import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './assets/styles/Global.css';
import './assets/styles/MediaQuery.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Installed from './Pages/Installed';
import UpComing from './Pages/UpComing';
import ChangePassword from './Pages/ChangePassword';
import RegisterForm from './Pages/authentication/RegisterForm';
import OtpVerification from './Pages/authentication/OtpVerification';
import LoginForm from './Pages/authentication/LoginForm';
import { Slide, toast, ToastContainer } from 'react-toastify';
import ForgotPassword from './Pages/authentication/ForgotPassword';
import Guide from './Pages/Guide';
import ResetPassword from './Pages/authentication/ResetPassword';
import Loader from './components/Loader';
import AuthGaurd from './components/Guards/AuthGaurd';
import UnAuthGaurd from './components/Guards/UnAuthGaurd';
import DashboardPerformance from './Pages/DashboardPerformance';
import CustomerInteractions from './Pages/CustomerInteractions';
import KnowledgeBaseSetMessage from './Pages/KnowledgeBaseSetMessage';
import { clearLocalStorage, getLocalStorage, getWithToken } from './API/Api';
import NotFound from './Pages/NotFound';
import ChatBotProfile from './Pages/ChatBotProfile';
import ThreeDotLoader from './components/ThreeDotLoader';
import AutoResponse from './Pages/AutoResponse';
import TestChatBot from './Pages/authentication/TestChatBot';
import UserProfile from './Pages/UserProfile';
import Policy from './components/Policy';
import WebsiteScrape from './Pages/WebsiteScrape';
import Layout from './components/Layout';
import WidgetChatbot from './components/WidgetChatbot';
import MyLeads from './Pages/MyLeads';
import NavTabLayout from './components/NavTabLayout ';
import CustomizeWidget from './Pages/CustomizeWidget';
import Subscription from './Pages/Subscription_method.js/Subscription';
import SubThankyou from './Pages/Subscription_method.js/SubThankyou';
import { createContext, useEffect, useState } from 'react';
import OpenAIConfiguration from './Pages/OpenAIConfiguration.js';

export const subContext = createContext();

function App() {
  const [subscriptionFlag, setSubscriptionFlag] = useState('');

  const [conversionData, setConversionData] = useState('');
  const [updateValue, setUpdateValue] = useState('');
  let token = getLocalStorage('apiToken');
  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      getSubscriptionDetails(JSON.parse(token));
      getConversion(JSON.parse(token));
    }
  }, [updateValue]);

  const getSubscriptionDetails = async (token) => {
    try {
      const response = await getWithToken('list_subscription', token);
      if (response?.data) {
        setSubscriptionFlag(response?.data);
      } else {
        console.log('No products found in the response.');
      }
    } catch (error) {
      console.error('Error while fetching subscription details:', error);
    }
  };

  const getConversion = (token) => {
    getWithToken('get_conversation', token)
      .then((response) => {
        if (response.status === 200) {
          setConversionData(response?.data);
          setUpdateValue('');
        } else if (response.code === 'token_not_valid') {
          clearLocalStorage();
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      <subContext.Provider value={{ subscriptionFlag, setUpdateValue, conversionData }}>
        <ToastContainer
          position='top-center'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
          style={{ zIndex: '99999' }}
        />
        <BrowserRouter>
          <Routes>
            {!token && <Route path='/' element={<Navigate to='/login' />} />}
            {token && <Route path='/' element={<Navigate to={`${subscriptionFlag?.user?.subscription != null ? '/dashboard' : '/chatbot/websitescrape'}`} />} />}

            <Route path='/' element={<Layout />}>
              <Route element={<AuthGaurd />}>
                <Route path='/dashboard' element={<DashboardPerformance />} />
                <Route path='/dashboard/customerinteractions' element={<CustomerInteractions />} />

                <Route path='/' element={<NavTabLayout />}>
                  <Route path='/chatbot' element={<ChatBotProfile />} />
                  <Route path='/chatbot/knowledgebase' element={<KnowledgeBaseSetMessage />} />
                  <Route path='/chatbot/websitescrape' element={<WebsiteScrape />} />
                  <Route path='/chatbot/customizewidget' element={<CustomizeWidget />} />
                  <Route path='/setting' element={<UserProfile />} />
                  <Route path='/setting/changepassword' element={<ChangePassword />} />
                  <Route path='/setting/openaiconfiguration' element={<OpenAIConfiguration />} />
                </Route>
                <Route path='/integrations' element={<Installed />} />
                <Route path='/agentknowledge/knowledgebase' element={<AutoResponse />} />
                <Route path='/subscription' element={<Subscription />} />
                <Route path='/subscription-thankyou' element={<SubThankyou />} />

                <Route path='/upcoming' element={<UpComing />} />
                <Route path='/guide' element={<Guide />} />
                <Route path='/leads' element={<MyLeads />} />
              </Route>
            </Route>
            <Route path='/preview' element={<WidgetChatbot />} />
            <Route path='/testchatbot' element={<TestChatBot />} />
            <Route path='/policy' element={<Policy />} />

            <Route path='/threedot' exact element={<ThreeDotLoader />} />

            <Route path='/loader' element={<Loader />} />

            <Route element={<UnAuthGaurd />}>
              <Route path='/' element={<LoginForm />}>
                <Route path='/login' element={<LoginForm />} />
              </Route>
              <Route path='/register' element={<RegisterForm />} />

              <Route path='/otpverification/:code' element={<OtpVerification />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
              <Route path='/resetpassword/:code/:code' element={<ResetPassword />} />
            </Route>
            <Route path='/*' exact element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </subContext.Provider>
    </>
  );
}

export default App;
