import React from 'react';
import { useNavigate } from 'react-router-dom';
import profileIcon from '../assets/images/default.jpg';
import { IoLogOutOutline } from 'react-icons/io5';
import { clearLocalStorage, getLocalStorage } from '../API/Api';

const Header = () => {
  const Navigate = useNavigate();

  const FullName = JSON.parse(getLocalStorage('FullName'));

  const handleClick = () => {
    clearLocalStorage();
    Navigate('/login');
  };

  return (
    <>
      <div className='header-bar'>
        <div className='d-flex align-items-center'>
          <p className='opensans-regular text-white mb-0'>{FullName}</p>

          <div className='dropdown custom-dropdown'>
            <button className='btn dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
              <img src={profileIcon} className='img-fluid' alt='Profile_icon' width='35' height='35' />
            </button>
            <ul className='dropdown-menu'>
              <li>
                <a className='dropdown-item text-center' href='#' onClick={handleClick}>
                  <IoLogOutOutline style={{ fontSize: '25px', marginRight: '10px' }} />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
