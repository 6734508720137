import React from 'react';
import ReactApexChart from 'react-apexcharts';

const NewUserChart = (props) => {
  const state = {
    series: [
      {
        name: 'Contact',
        type: 'bar',
        data: [15, 60, 10, 35, 20, 15, 35] || props?.data?.data,
      },
    ],

    options: {
      chart: {
        height: 180,
        type: 'bar',
        toolbar: {
          show: false,
          autoSelected: false,
          tools: {
            download: false,
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 0,
          borderRadiusApplication: 5,
          borderRadiusWhenStacked: 0,
          dataLabels: {
            position: 'center',
          },
          strokeWidth: 3,
          columnWidth: '18%',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          colorStops: [
            {
              offset: 0,
              color: '#06E3F4',
            },
            {
              offset: 25,
              color: '#06E3F4',
            },
            {
              offset: 50,
              color: '#06E3F4',
            },
            {
              offset: 100,
              color: '#107DD3',
            },
          ],
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: '20px',
          colors: ['#ffffff'],
        },
      },

      grid: {
        show: true,
        borderColor: '#5356fb87',
        strokeDashArray: 10,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      xaxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: '#A199E2',
            fontSize: '14px',
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <div className='react-chart'>
      <ReactApexChart options={state.options} series={state.series} type='bar' height={180} />
    </div>
  );
};

export default NewUserChart;
