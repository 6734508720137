import React, { useRef, useEffect } from 'react';

const ScrollToBottom = ({ scrollChatData, showForm, scrollLoader, showRecommendation }) => {
  const elementRef = useRef();

  useEffect(() => {
    elementRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }, [scrollChatData, showForm, scrollLoader, showRecommendation]);

  return <div ref={elementRef} />;
};

export default ScrollToBottom;
