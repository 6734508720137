import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearLocalStorage, deleteWithToken, getLocalStorage, getWithToken, patchAvtarWithToken, patchWithToken } from '../API/Api';
import Loader from '../components/Loader';
import { MdContentCopy } from 'react-icons/md';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiCloseCircleLine } from 'react-icons/ri';
import BG_CHATBOTS from '../assets/images/default-image.png';
import WidgetChatbot from '../components/WidgetChatbot';
import BUBBlE_ICON from '../assets/icons/ChirpfloIcon-V2.0/chatbot-icon.svg';
import UPLOAD_ICON from '../assets/icons/ChirpfloIcon-V2.0/upload-icon.svg';
import SHARE_ICON from '../assets/icons/ChirpfloIcon-V2.0/share-icon.svg';
import INFO_ICON from '../assets/icons/ChirpfloIcon-V2.0/Info-Icon.svg';
import { subContext } from '../App';

const CustomizeWidget = () => {
  const [avatarPreview, setAvatarPreview] = useState('');
  const [bgPreview, setBgPreview] = useState('');
  const [widgetCode, setWidgetCode] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [bgPopup, setBgPopup] = useState(false);
  const [apiTokenData, setApiTokenData] = useState('');
  const Navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [companyData, setCompanyData] = useState('');
  const [logoPreview, setLogoPreview] = useState('');
  const [faqPopup, setFaqPopup] = useState(false);
  const subsFlag = useContext(subContext);

  const [instruction, setInstruction] = useState('Find the </head> tag and paste the code snippet just before it. Save the HTML file.');

  const [widgetValues, setWidgetValues] = useState({
    id: '',
    chat_name: '',
    heading: '',
    sub_heading: '',
    chat_status: '',
    buble_active_status: '',
    color: '',
    chatbot_avtar: '',
    chatbot_logo_size: '',
    chatbot_logo: '',
    company: '',
    launcher_icon: '',
    default_launcher_icon: '',
    popup_status: '',
    timer_count: 0,
    background_color: '',
    client_bubble_color: '',
    website: '',
    bg_chatbot: '',
    text_color: '',
    bubble_text_color: '',
    agent_bubble_text_color: '',
  });

  const [imgUrlPath, setImgUrlPath] = useState({
    id: '1',
    link: '',
    status: '',
  });
  const {
    chat_name,
    heading,
    sub_heading,
    chatbot_logo,
    chatbot_logo_size,
    company,
    color,
    chatbot_avtar,
    launcher_icon,
    default_launcher_icon,
    chat_status,
    buble_active_status,
    popup_status,
    timer_count,
    launcher_icon_status,
    client_bubble_color,
    website,
    background_color,
    bg_chatbot,
    text_color,
    bubble_text_color,
    agent_bubble_text_color,
  } = widgetValues;

  useEffect(() => {
    window.scrollTo(0, 0);
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      getWidgetDetails(JSON.parse(token));
      getScriptCode(JSON.parse(token));
      getBotDetails(JSON.parse(token));
    } else {
      Navigate('/login');
    }
  }, []);

  const getBotDetails = (token) => {
    setLoader(true);
    getWithToken('company/', token)
      .then((response) => {
        if (response.status == 201) {
          setCompanyData(response.data);
        } else if (response.code == 'token_not_valid') {
          clearLocalStorage();
        }
        if (response.status == 200) {
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };

  const LauncherIconChange = (image, index) => {
    const updatedIcons = default_launcher_icon.map((icon, i) => {
      if (i === index) {
        return {
          ...icon,
          status: true,
        };
      } else {
        return {
          ...icon,
          status: false,
        };
      }
    });
    setWidgetValues((prevValues) => ({
      ...prevValues,
      default_launcher_icon: updatedIcons,
    }));

    setImgUrlPath({
      id: index + 1,
      link: image.link,
      status: updatedIcons[index].status,
    });
  };

  const userHandleChange = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setWidgetValues({ ...widgetValues, [e.target.name]: file });

      // Generate a preview of the uploaded image
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);

      let fd = new FormData();
      fd.append('chatbot_avtar', file);

      try {
        const response = await patchAvtarWithToken('Avtar_Image_WidgetAPI/', apiTokenData, fd);
        if (response.status === 200) {
          toast.success(response.message);
          getWidgetDetails(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setWidgetValues({
        ...widgetValues,
        [e.target.name]: e.target.value || '',
      });
    }
  };

  const logoIconHandleChange = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      // setLogoPreviewName(file?.name);
      setWidgetValues({ ...widgetValues, [e.target.name]: file });

      // Generate a preview of the uploaded image
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);

      let fd = new FormData();
      fd.append('chatbot_logo', file);

      try {
        const response = await patchAvtarWithToken('Logo_Image_WidgetAPI/', apiTokenData, fd);
        if (response.status === 200) {
          toast.success(response.message);
          getWidgetDetails(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getWidgetDetails = (token) => {
    setLoader(true);
    getWithToken('Webchat_widgetAPI/', token)
      .then((response) => {
        if (response.status === 200) {
          setWidgetValues({
            id: response?.data?.id,
            chat_name: response?.data?.name,
            heading: response?.data?.heading,
            chatbot_logo: response?.data?.chatbot_logo,
            chatbot_logo_size: response?.data?.chatbot_logo_size,
            sub_heading: response?.data?.sub_heading,
            chat_status: response?.data?.status,
            buble_active_status: response?.data?.buble_active_status,
            color: response?.data?.color,
            default_launcher_icon: response?.data?.default_launcher_icon,
            chatbot_avtar: response?.data?.chatbot_avtar,
            launcher_icon: response?.data?.launcher_icon,
            company: response?.data?.company,
            popup_status: response?.data?.popup_status,
            launcher_icon_status: response?.data?.launcher_icon_status,
            timer_count: response?.data?.timer_count,
            background_color: response?.data?.background_color,
            bg_chatbot: response?.data?.bg_chatbot,
            client_bubble_color: response?.data?.client_bubble_color,
            website: response?.data?.website,
            text_color: response?.data?.text_color,
            bubble_text_color: response?.data?.bubble_text_color,
            agent_bubble_text_color: response?.data?.agent_bubble_text_color,
          });
          if (response?.data?.launcher_icon_status) {
            setImgUrlPath({
              id: '',
              link: response.data.launcher_icon,
              // status: false,
            });
          } else {
            response?.data?.default_launcher_icon.map((data, index) => {
              if (data.status) {
                setImgUrlPath({
                  id: index + 1,
                  link: data.link,
                });
              }
            });
          }
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        // toast.error('Something went wrong');
        setLoader(false);
      });
  };

  const getScriptCode = (token) => {
    getWithToken('Copy_WidgetAPI/', token)
      .then((response) => {
        const textToCopy = `<script src="${response.data.js_script_file}"></script>
      <script src="http://webapi.chirpflo.com/media/logo/Widget.js"></script>`;
        setWidgetCode(textToCopy);
      })
      .catch((error) => {
        // toast.error('Something went wrong');
      });
  };

  const UpdateChatBotData = async (e, keyName) => {
    if (state != null && state?.faqFlag) {
      setFaqPopup(true);
    }
    e.preventDefault();

    let fd = new FormData();
    fd.append('default_launcher_icon', imgUrlPath?.id);
    fd.append('name', chat_name);
    fd.append('heading', heading);
    fd.append('sub_heading', sub_heading);
    fd.append('status', keyName === 'chat_status' ? !chat_status : chat_status);
    fd.append('buble_active_status', keyName === 'buble_active_status' ? !buble_active_status : buble_active_status);
    fd.append('color', color);
    fd.append('popup_status', keyName === 'popup_status' ? !popup_status : popup_status);
    fd.append('timer_count', timer_count);
    fd.append('background_color', background_color);
    fd.append('client_bubble_color', client_bubble_color);
    fd.append('website', website);
    fd.append('text_color', text_color);
    fd.append('bubble_text_color', bubble_text_color);
    fd.append('agent_bubble_text_color', agent_bubble_text_color);
    fd.append('chatbot_logo_size', chatbot_logo_size);

    patchAvtarWithToken('Webchat_widgetAPI/', apiTokenData, fd)
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss();
          toast.success(response.message);
          getWidgetDetails(apiTokenData);
          scriptCode(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error('Error occurred:', error);
        toast.error('An error occurred while updating chat bot data');
      });
  };

  const handleCopyText = () => {
    const tempInput = document.createElement('textarea');
    tempInput.value = widgetCode;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setCopySuccess(true);
    setTimeout(function () {
      setCopySuccess(false);
    }, 2000);
  };

  const handleNavigatePreview = () => {
    const data = {
      id: widgetValues.id,
      previewData: true,
      company_id: companyData?.id,
    };

    const url = '/preview';
    const params = new URLSearchParams(data);
    const fullUrl = `${url}?${params.toString()}`;

    const newWindow = window.open(fullUrl, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const userBgImageChange = async (e) => {
    const file = e.target.files[0];
    setWidgetValues({ ...widgetValues, [e.target.name]: file });

    // Generate a preview of the uploaded image
    const reader = new FileReader();
    reader.onload = () => {
      setBgPreview(reader.result);
    };
    reader.readAsDataURL(file);

    let fd = new FormData();
    fd.append('bg_chatbot', file);
    try {
      const response = await patchAvtarWithToken('Widget_bg_ColorAPI/', apiTokenData, fd);
      if (response.status === 200) {
        toast.success(response.message);
        getWidgetDetails(apiTokenData);
      } else if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const launcherIconHandleChange = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.append('launcher_icon', e.target.files[0]);

    patchAvtarWithToken('Icons_Image_WidgetAPI/', apiTokenData, fd)
      .then((response) => {
        if (response.status === 200) {
          setImgUrlPath({
            id: '',
            link: response.data.launcher_icon,
            // status: false,
          });
          toast.success(response.message);
          getWidgetDetails(apiTokenData);
        } else if (response.status === 400) {
          toast.error(response.message);
        }
      })
      .catch((error) => {});
  };

  const scriptCode = () => {
    const JS_Payload = {
      js: `const id='${widgetValues.id}';`,
    };

    patchWithToken('Copy_WidgetAPI/', apiTokenData, JS_Payload)
      .then((response) => {
        if (response.status == 200) {
          const textToCopy = `<script src="${response.data.js_script_file}"></script>
<script src="http://webapi.chirpflo.com/media/logo/Widget.js"></script>`;
          setWidgetCode(textToCopy);
        }
      })
      .catch((error) => {});
  };

  const handleBgDelete = async () => {
    try {
      const response = await deleteWithToken('Widget_bg_ColorAPI/', apiTokenData);
      if (response.status === 200) {
        setBgPopup(false);
        toast.success(response.message);
        getWidgetDetails(apiTokenData);
        setBgPreview('');
      } else if (response.status === 400) {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyToClipboard = async () => {
    const data = {
      id: widgetValues.id,
      previewData: true,
      company_id: companyData?.id,
    };
    const url = '/preview';
    const params = new URLSearchParams(data);
    const fullUrl = `https://chirpflo.com${url}?${params.toString()}`;

    try {
      await navigator.clipboard.writeText(fullUrl);
      toast.info('URL copied');
    } catch (error) {
      console.error('Failed to copy URL to clipboard:', error);
    }
  };

  return (
    <>
      <div className='row m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-3 pe-sm-3 pe-3 mb-5'>
        <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
          <div className='chatbox-field bg-themeLightBlue'>
            <div className='mb-5'>
              <h4 className='opensans-bold text-white mb-4'>Customized Your Chatbot</h4>
              <p className='mb-0 opensans-regular color-theme-darkLightblue font18 text-justify'>
                Alright, it's time to make Chirpflo visually match your brand with ease!. Here you can customize the slide-out look, the timer, the bubbles, buttons, logos, and
                more to match your brand perfectly. You can even preview and share your agents once you're done before you deploy Chirpflo onto your website. 🎉
              </p>
            </div>

            <div className='col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
              <div className='px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
                <div className='custom-part'>
                  <span className='opensans-semibold'>Get started</span>
                </div>
                <div className='d-flex align-items-center justify-content-between  input-GPT new'>
                  <label className='opensans-regular text-white font-20 me-3 pb-0'>Active Chatbot</label>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id=''
                      name='chat_status'
                      checked={widgetValues.chat_status}
                      onChange={(e) => UpdateChatBotData(e, 'chat_status')}
                    />
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between  input-GPT new'>
                  <label className='opensans-regular text-white font-20 me-3 pb-0'>Show Bubble</label>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      role='switch'
                      id=''
                      name='buble_active_status'
                      checked={widgetValues.buble_active_status}
                      onChange={(e) => UpdateChatBotData(e, 'buble_active_status')}
                    />
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='chatbox-input-text d-flex align-items-center'>
                    <label className='opensans-medium me-5'>Auto Slide</label>
                    <div className='chatbox-input-field'>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          id=''
                          name='popup_status'
                          checked={widgetValues.popup_status}
                          onChange={(e) => UpdateChatBotData(e, 'popup_status')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='chatbox-input-text mb-0'>
                    <label className='opensans-medium'>Slider Timer</label>
                    <div className='chatbox-input-field'>
                      <input
                        type='number'
                        placeholder='000'
                        className={`form-control chatbox-input counter-input box-shadow-hover ${!popup_status ? 'cursor-notAllowed' : ''}`}
                        name='timer_count'
                        value={timer_count}
                        autoComplete='off'
                        onChange={userHandleChange}
                        disabled={!popup_status}
                        min='0'
                      />
                    </div>
                  </div>
                </div>
                <div className='custom-hr'></div>
                <div className='custom-part'>
                  <span className='opensans-semibold'>Header Part</span>
                </div>
                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Logo</label>
                  <div className='form-group inputDnD'>
                    {/* <img src={logoPreview} alt='upload-icon' width='30' height='30' /> */}
                    <label className='sr-only' for='inputFile'></label>
                    <div>
                      {logoPreview || chatbot_logo ? (
                        <img src={logoPreview || chatbot_logo} alt='upload-icon' width='100' height='40' />
                      ) : (
                        <img src={UPLOAD_ICON} alt='upload-icon' width='30' height='30' />
                      )}
                      <h6 className='opensans-semibold text-white my-2'>Upload Logo</h6>
                      {/* <p className='opensans-semibold color-theme-darkLightblue my-2'>{logoPreviewName}</p> */}
                      <p className='opensans-regular color-theme-darkLightblue m-0'>
                        JPG, PNG, GIF, SVG, WEBP <br />
                        Max: 5mb
                      </p>
                      <input
                        type='file'
                        className='form-control-file text-primary font-weight-bold'
                        id='inputFile'
                        accept='image/*'
                        data-title='Drag and drop a file'
                        onChange={(e) => logoIconHandleChange(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Logo Size</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='number'
                      placeholder='Logo Size'
                      className='form-control chatbox-input box-shadow-hover'
                      name='chatbot_logo_size'
                      value={chatbot_logo_size}
                      autoComplete='off'
                      onChange={userHandleChange}
                    />
                  </div>
                </div> */}
                {/* <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Full Name</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='text'
                      placeholder='Full Name'
                      className='form-control chatbox-input box-shadow-hover'
                      name='chat_name'
                      value={chat_name}
                      autoComplete='off'
                      onChange={userHandleChange}
                    />
                  </div>
                </div> */}
                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Heading</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='text'
                      placeholder='Heading'
                      className='form-control chatbox-input box-shadow-hover'
                      name='heading'
                      value={heading}
                      autoComplete='off'
                      onChange={userHandleChange}
                    />
                  </div>
                </div>
                {/* <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Sub Heading</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='text'
                      placeholder='Sub Heading'
                      className='form-control chatbox-input box-shadow-hover'
                      name='sub_heading'
                      value={sub_heading}
                      autoComplete='off'
                      onChange={userHandleChange}
                    />
                  </div>
                </div> */}

                <div className='custom-hr'></div>

                <div className='custom-part'>
                  <span className='opensans-semibold'>Agent bubble</span>
                </div>

                <div className='chatbox-input-text align-items-start input-GPT new mb-3'>
                  <label className='opensans-bold mb-1'>Avatar</label>
                  <div className='profile-pic-wrapper d-flex align-items-center'>
                    <div className='pic-holder'>
                      {avatarPreview ? <img id='profilePic' className='pic' src={avatarPreview} /> : <img id='profilePic' className='pic' src={chatbot_avtar} />}
                    </div>
                    <div className='avtar-upload-button ms-3 text-left'>
                      <input type='file' name='chatbot_avtar' id='newProfilePhoto' accept='image/*' className='d-none' onChange={userHandleChange} />
                      <label htmlFor='newProfilePhoto' className='pb-0 opensans-bold' style={{ cursor: 'pointer' }}>
                        Upload Icon
                      </label>
                      <p className='opensans-regular color-theme-darkLightblue mb-0'>{`JPG, PNG <5mb`} </p>
                    </div>
                  </div>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Bubble Color</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='color' className='' value={color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Bubble Text</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{agent_bubble_text_color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='agent_bubble_text_color' className='' value={agent_bubble_text_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='custom-hr'></div>

                <div className='custom-part'>
                  <span className='opensans-semibold'>Visitor bubble</span>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Bubble Color</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{client_bubble_color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='client_bubble_color' className='' value={client_bubble_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Bubble Text</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{bubble_text_color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='bubble_text_color' className='' value={bubble_text_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='custom-hr'></div>

                <div className='custom-part'>
                  <span className='opensans-semibold'>Chat Window</span>
                </div>

                <div className='chatbox-input-text align-items-start input-GPT new'>
                  <label className='opensans-bold'>
                    Background Image
                    <div className='custom-tooltip-container'>
                      <img src={INFO_ICON} alt='info-icon' width='17' height='17' className='ms-1 cursor-pointer' />
                      <span className='custom-tooltip'>Please upload a mobile vertical-type image background.</span>
                    </div>
                  </label>
                  <div className='profile-pic-wrapper background-image d-flex align-items-center'>
                    <div className='pic-holder'>
                      {bgPreview ? (
                        <img id='profilePic' className='pic object-fit-cover' src={bgPreview} />
                      ) : (
                        <img id='profilePic' className='pic object-fit-cover' src={bg_chatbot || BG_CHATBOTS} />
                      )}
                      {bg_chatbot != null && companyData != '' && (
                        <span className='bg-close' onClick={() => setBgPopup(true)}>
                          <RiCloseCircleLine />
                        </span>
                      )}
                    </div>
                    <div className='avtar-upload-button ms-3 text-left'>
                      <input type='file' name='bg_chatbot' id='newBackgroundImage' accept='image/*' className='d-none' onChange={userBgImageChange} />
                      <label htmlFor='newBackgroundImage' className='pb-0 opensans-bold'>
                        Upload Icon
                      </label>
                      <p className='opensans-regular color-theme-darkLightblue mb-0'>{`JPG, PNG <5mb`} </p>
                    </div>
                  </div>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Background</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{background_color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='background_color' className='' value={background_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Text</label>
                  <div className='chatbox-input-field'>
                    <div className='form-control chatbox-input box-shadow-hover d-flex align-items-center justify-content-between'>
                      <p className='opensans-medium font-18 color-theme-darkLightblue m-0'>{text_color}</p>
                      <div className='d-flex align-items-center justify-content-center'>
                        <input type='color' name='text_color' className='' value={text_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='custom-hr'></div>

                <div className='custom-part'>
                  <span className='opensans-semibold'>Toggle</span>
                </div>

                <div className='chatbox-input-text align-items-start input-GPT new mb-3'>
                  {companyData != '' && (
                    <div className='mb-3'>
                      <div className='input-GPT new'>
                        <label className='opensans-bold mb-1'>Chat bubble icon</label>
                        <div className='profile-pic-wrapper d-flex align-items-center'>
                          <div className='pic-holder'>
                            <img id='profilePic' className='pic' src={BUBBlE_ICON} />
                          </div>
                          <div className='avtar-upload-button ms-3 text-left'>
                            <input type='file' name='chatbot_avtar' id='fileInput' accept='image/*' className='d-none' onChange={(e) => launcherIconHandleChange(e)} />
                            <label htmlFor='fileInput' className='pb-0 opensans-bold' style={{ cursor: 'pointer' }}>
                              Upload Icon
                            </label>
                            <p className='opensans-regular color-theme-darkLightblue mb-0'>{`JPG, PNG, GIF, SVG, WEBP <5mb`} </p>
                          </div>
                        </div>
                      </div>
                      <div className='chatbox-input-text input-GPT align-items-start new '>
                        <label className='opensans-bold me-5'>Chat bubble icon</label>
                        <div className='chatbox-input-field d-flex flex-wrap align-items-center'>
                          <div className='d-flex flex-wrap align-items-center'>
                            {widgetValues?.launcher_icon?.length > 0 && (
                              <div className={`chatboxupdate-logo me-3 ${widgetValues.launcher_icon_status ? 'active' : ''}`}>
                                <img src={launcher_icon} alt='chatbox-logo' className='launcher-icon' />
                              </div>
                            )}
                          </div>
                          <div className=' d-flex flex-wrap align-items-center'>
                            {default_launcher_icon.length > 0 &&
                              default_launcher_icon?.map((image, index) => (
                                <div key={index} className={`chatboxupdate-logo me-3 ${image.status ? 'active' : ''}`} onClick={() => LauncherIconChange(image, index)}>
                                  <img src={image.link} alt='chatbox-logo' className='launcher-icon' />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='chatbox-input-text input-GPT new mb-3'>
                  <label className='opensans-medium'>Website To Preview On</label>
                  <div className='chatbox-input-field'>
                    <input
                      type='text'
                      placeholder='Website Link'
                      name='website'
                      value={website}
                      className='form-control chatbox-input box-shadow-hover'
                      onChange={userHandleChange}
                    />
                  </div>
                </div>

                <div className='d-flex flex-wrap align-items-center pt-3'>
                  <button className='btn btn-submit-login mt-lg-0 mb-3 mb-lg-0 px-4 px-lg-5 me-lg-3' onClick={UpdateChatBotData}>
                    Save
                  </button>
                  <button className='btn btn-submit-copy btn-custom-border px-4 px-lg-5 me-lg-3' type='button' onClick={() => handleNavigatePreview()}>
                    Preview
                  </button>
                  {subsFlag?.subscriptionFlag?.user?.subscription?.name != null && (
                    <button className='btn border-0 d-flex align-items-center' type='button' onClick={() => copyToClipboard()}>
                      <img src={SHARE_ICON} alt='share-icon' width='15' height='15' className='me-2' /> <span className='lead-summary-color opensans-bold'> Share Agent</span>
                    </button>
                  )}
                </div>

                {subsFlag?.subscriptionFlag?.user?.subscription?.name != null && <div className='custom-hr'></div>}
              </div>
            </div>

            {subsFlag?.subscriptionFlag?.user?.subscription?.name != null && (
              <div className='mt-5 px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
                <h3 className='opensans-bold mb-0 lead-summary-color'>Chirpflo Widget Instructions</h3>
                {widgetCode != '' && (
                  <div className='chatbox-input-text input-GPT new mt-3'>
                    <label className='opensans-medium'></label>
                    <div className='chatbox-input-field'>
                      <div className='input-group flex-nowrap mb-3'>
                        <textarea
                          type='text'
                          className='form-control chatbox-input'
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby='basic-addon2'
                          value={widgetCode}
                          style={{ height: '60px' }}
                          disabled
                        />
                        <span className='input-group-text px-4 cursor-pointer' id='basic-addon2' onClick={handleCopyText}>
                          {!copySuccess ? <MdContentCopy /> : <AiOutlineCheck />}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className=''>
                  <p className='opensans-regular m-0 text-white pt-2'>
                    To seamlessly integrate chirpflo with your website, please refer to the instructions below for integrating the code with the appropriate location on your site.
                  </p>
                </div>
                <div className='my-3 '>
                  <h4 className='guide-subheading text-white opensans-bold'>Please begin by following these basic steps:</h4>
                </div>
                <p className='opensans-regular mb-3 text-white'>Follow the provided instructions to customize your Chatbot and complete all the necessary processes.</p>
                <div className='chirpflo-widget'>
                  <ul>
                    <li>Click the "save button" to generate the widget script and copy the code provided.</li>
                    <li>Once the steps are completed, preview any updates or modifications by clicking the preview button.</li>
                    <li>Please note that some websites may not display the preview correctly.</li>
                    <li>Navigate to your website and open the HTML file using a text editor.</li>
                    <li>{instruction}</li>
                    <li>Refresh your website, and you should now see the Chatbot visible, usually appearing as a symbol or icon in the bottom right corner of the webpage.</li>
                  </ul>
                </div>
                <div className='my-3'>
                  <h4 className='guide-subheading text-white opensans-bold'>IMPORTANT NOTE :</h4>
                </div>
                <div className='my-3'>
                  <p className='opensans-regular m-0 text-white'>
                    It is important to note that modifying your website's code, partilarly if you are not familiar with the code, can cause issues with your website. Be sure to
                    test the changes and make a backup of your website before making any code modifications.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0 px-sm-3 px-0'>
          <WidgetChatbot widgetID={widgetValues.id} widgetAllData={widgetValues} />
        </div>
      </div>
      {/* <div className='m-0 mt-5 ps-md-5 ps-sm-3 ps-3 pe-md-5 pe-sm-3 pe-3 mb-3'>
        <div className='bg-themeLightBlue'>
          <div className='chatbox-field '>
            <div className='d-flex flex-wrap align-items-center justify-content-between position-relative pb-md-5 pb-3'>
              <h3 className='opensans-bold text-white mb-0'>Customized Your Chatbot</h3>
              <div className='d-flex align-items-center justify-content-end flex-row chatbox-input-text toogle-position mt-md-0 mt-2'>
                <label className='opensans-bold me-3'>Toggle</label>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id=''
                    name='chat_status'
                    checked={widgetValues.chat_status}
                    onChange={(e) => UpdateChatBotData(e, 'chat_status')}
                  />
                </div>
              </div>
            </div>
            <div className='row justify-content-between mx-0'>
              <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 p-0'>
                <div className='px-2 px-md-3 px-lg-2 px-xl-3 px-xxl-5'>
                  <div className='chatbox-input-text input-GPT new mb-3'>
                    <label className='opensans-medium'>Full Name</label>
                    <div className='chatbox-input-field'>
                      <input
                        type='text'
                        placeholder='Full Name'
                        className='form-control chatbox-input box-shadow-hover'
                        name='chat_name'
                        value={chat_name}
                        autoComplete='off'
                        onChange={userHandleChange}
                      />
                    </div>
                  </div>
                  <div className='chatbox-input-text input-GPT new mb-3'>
                    <label className='opensans-medium'>Heading</label>
                    <div className='chatbox-input-field'>
                      <input
                        type='text'
                        placeholder='Heading'
                        className='form-control chatbox-input box-shadow-hover'
                        name='heading'
                        value={heading}
                        autoComplete='off'
                        onChange={userHandleChange}
                      />
                    </div>
                  </div>
                  <div className='chatbox-input-text input-GPT new mb-3'>
                    <label className='opensans-medium'>Sub Heading</label>
                    <div className='chatbox-input-field'>
                      <input
                        type='text'
                        placeholder='Sub Heading'
                        className='form-control chatbox-input box-shadow-hover'
                        name='sub_heading'
                        value={sub_heading}
                        autoComplete='off'
                        onChange={userHandleChange}
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='chatbox-input-text d-flex align-items-center  input-GPT new mb-3'>
                      <label className='opensans-medium me-5'>Popup</label>
                      <div className='chatbox-input-field'>
                        <div className='form-check form-switch'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            role='switch'
                            id=''
                            name='popup_status'
                            checked={widgetValues.popup_status}
                            onChange={(e) => UpdateChatBotData(e, 'popup_status')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='chatbox-input-text input-GPT new mb-3'>
                      <label className='opensans-medium'>Timer Count</label>
                      <div className='chatbox-input-field'>
                        <input
                          type='number'
                          placeholder='000'
                          className='form-control chatbox-input counter-input box-shadow-hover'
                          name='timer_count'
                          value={timer_count}
                          autoComplete='off'
                          onChange={userHandleChange}
                          disabled={!popup_status}
                          min='0'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap justify-content-between'>
                    <div className='chatbox-input-text input-GPT new'>
                      <label className='opensans-medium mb-1'>Background</label>
                      <div className='chatbox-input-field'>
                        <input type='color' name='background_color' className='' value={background_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                    <div className='chatbox-input-text input-GPT new mb-3'>
                      <label className='opensans-medium mb-1'>Chat Bubble</label>
                      <div className='chatbox-input-field'>
                        <input type='color' name='color' className='' value={color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                    <div className='chatbox-input-text input-GPT new mb-3'>
                      <label className='opensans-medium mb-1'>Chat Bubble II</label>
                      <div className='chatbox-input-field'>
                        <input type='color' name='client_bubble_color' className='' value={client_bubble_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                    <div className='chatbox-input-text input-GPT new mb-3'>
                      <label className='opensans-medium mb-1'>Bubble Text</label>
                      <div className='chatbox-input-field'>
                        <input type='color' name='bubble_text_color' className='' value={bubble_text_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                    <div className='chatbox-input-text input-GPT new mb-3'>
                      <label className='opensans-medium mb-1'>General Text</label>
                      <div className='chatbox-input-field'>
                        <input type='color' name='text_color' className='' value={text_color} autoComplete='off' onChange={userHandleChange} />
                      </div>
                    </div>
                  </div>
                  <div className='chatbox-input-text align-items-start input-GPT new mb-3'>
                    <div className='chatbox-input-field d-flex flex-wrap align-items-center justify-content-between'>
                      <div className=''>
                        <label>Avatar</label>
                        <div className='profile-pic-wrapper d-flex align-items-center'>
                          <div className='pic-holder'>
                            {avatarPreview ? <img id='profilePic' className='pic' src={avatarPreview} /> : <img id='profilePic' className='pic' src={chatbot_avtar} />}
                          </div>
                          <div className='Avtar-upload-button ms-4'>
                            <input type='file' name='chatbot_avtar' id='newProfilePhoto' accept='image/*' onChange={userHandleChange} />
                            <label htmlFor='newProfilePhoto'>Upload</label>
                          </div>
                        </div>
                      </div>
                      <div className=''>
                        <label>
                          Background Image<span className='opacity-50'> (Optional)</span>
                        </label>
                        <div className='profile-pic-wrapper background-image d-flex align-items-center'>
                          <div className='pic-holder'>
                            {bgPreview ? <img id='profilePic' className='pic' src={bgPreview} /> : <img id='profilePic' className='pic' src={bg_chatbot || BG_CHATBOTS} />}
                            {bg_chatbot != null && companyData != '' && (
                              <span className='bg-close' onClick={() => setBgPopup(true)}>
                                <RiCloseCircleLine />
                              </span>
                            )}
                          </div>
                          <div className='Avtar-upload-button ms-4'>
                            <input type='file' name='bg_chatbot' id='newBackgroundImage' accept='image/*' onChange={userBgImageChange} />
                            <label htmlFor='newBackgroundImage'>Upload</label>
                          </div>
                        </div>
                      </div>
                      <div className='profile-pic-wrapper background-image'></div>
                    </div>
                  </div>

                  <div className='chatbox-input-text input-GPT new mb-3'>
                    <label className='opensans-medium'>Website To Preview On</label>
                    <div className='chatbox-input-field'>
                      <input
                        type='text'
                        placeholder='Website Link'
                        name='website'
                        value={website}
                        className='form-control chatbox-input box-shadow-hover'
                        onChange={userHandleChange}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-wrap align-items-center pt-3'>
                    <button className='btn btn-submit-login mt-lg-0 px-5' onClick={UpdateChatBotData}>
                      Save
                    </button>
                    <button className='btn  btn-submit-copy btn-custom-border ms-md-4 ms-0 my-md-0 my-3 px-5' type='button' onClick={() => handleNavigatePreview()}>
                      Preview
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 p-0 mt-xl-0 mt-3'>
                <div className='d-flex flex-column align-items-center'>
                  <WidgetChatbot widgetID={widgetValues.id} widgetAllData={widgetValues} />
                  {companyData != '' && (
                    <div className='chatbox-input-text input-GPT align-items-start new mb-3 mt-5'>
                      <div className='chatbox-input-field d-flex flex-wrap align-items-center'>
                        <div className=' d-flex flex-wrap align-items-center'>
                          {default_launcher_icon.length > 0 &&
                            default_launcher_icon?.map((image, index) => (
                              <div key={index} className={`chatboxupdate-logo me-3 ${image.status ? 'active' : ''}`} onClick={() => LauncherIconChange(image, index)}>
                                <img src={image.link} alt='chatbox-logo' />
                              </div>
                            ))}
                        </div>
                        <p className='opensans-bold text-white mb-0 me-3'>Or</p>
                        <div className='d-flex flex-wrap align-items-center'>
                          {widgetValues?.launcher_icon?.length > 0 && (
                            <div className={`chatboxupdate-logo me-3 ${widgetValues.launcher_icon_status ? 'active' : ''}`}>
                              <img src={launcher_icon} alt='chatbox-logo' className='launcher-icon' />
                            </div>
                          )}
                          <div className='launcher-upload-button'>
                            <input type='file' id='fileInput' onChange={(e) => launcherIconHandleChange(e)} />
                            <label for='fileInput'>Upload</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row pt-5'>
              <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12'>
                <h3 className='opensans-bold mb-0 color-theme-mainlyBlue '>Chirpflo Widget Instructions</h3>
                {widgetCode != '' && (
                  <div className='chatbox-input-text input-GPT new mt-3'>
                    <label className='opensans-medium'></label>
                    <div className='chatbox-input-field'>
                      <div className='input-group flex-nowrap mb-3'>
                        <textarea
                          type='text'
                          className='form-control chatbox-input'
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby='basic-addon2'
                          value={widgetCode}
                          style={{ height: '60px' }}
                          disabled
                        />
                        <span className='input-group-text px-4 cursor-pointer' id='basic-addon2' onClick={handleCopyText}>
                          {!copySuccess ? <MdContentCopy /> : <AiOutlineCheck />}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className=''>
                  <p className='opensans-regular m-0 text-white pt-2'>
                    To seamlessly integrate chirpflo with your website, please refer to the instructions below for integrating the code with the appropriate location on your site.
                  </p>
                </div>
                <div className='my-3 '>
                  <h4 className='guide-subheading text-white opensans-bold'>Please begin by following these basic steps:</h4>
                </div>
                <p className='opensans-regular mb-3 text-white'>Follow the provided instructions to customize your Chatbot and complete all the necessary processes.</p>
                <div className='chirpflo-widget'>
                  <ul>
                    <li>Click the "save button" to generate the widget script and copy the code provided.</li>
                    <li>Once the steps are completed, preview any updates or modifications by clicking the preview button.</li>
                    <li>Please note that some websites may not display the preview correctly.</li>
                    <li>Navigate to your website and open the HTML file using a text editor.</li>
                    <li>{instruction}</li>
                    <li>Refresh your website, and you should now see the Chatbot visible, usually appearing as a symbol or icon in the bottom right corner of the webpage.</li>
                  </ul>
                </div>
                <div className='my-3'>
                  <h4 className='guide-subheading text-white opensans-bold'>IMPORTANT NOTE :</h4>
                </div>
                <div className='my-3'>
                  <p className='opensans-regular m-0 text-white'>
                    It is important to note that modifying your website's code, partilarly if you are not familiar with the code, can cause issues with your website. Be sure to
                    test the changes and make a backup of your website before making any code modifications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {loader && (
        <div className='loader-center'>
          <Loader />
        </div>
      )}
      {bgPopup && (
        <div className='custom-popup'>
          <div className='popup-content'>
            <h4 className='opensans-bold color-theme-darkLightblue'>Are you sure you want to delete?</h4>
            <hr />
            {/* <div className={'popup-head'}>
              <p className='opensans-regular'> </p>
            </div>
            <hr /> */}
            <div className='popup-footer'>
              <button className='btn success-btn mx-1 text-capitalize text-white bg-danger' onClick={() => handleBgDelete()}>
                Delete
              </button>
              <button className='btn danger-btn mx-1 text-capitalize' data-dismiss='modal' onClick={() => setBgPopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {faqPopup && (
        <div className='custom-popup'>
          <div className='popup-content text-center'>
            <h4 className='opensans-bold color-theme-darkLightblue'>Don't forget to see how well Chirpflo generated your knowledge hub with its amazing customized training.</h4>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn success-btn text-capitalize me-2 text-white bg-theme-gradientBlue'
                style={{ border: 'none' }}
                onClick={() => Navigate('/chatbot/websitescrape', { state: { faqFlag: true, id: state?.id, length: state?.length } })}
              >
                View Knowledge-Hub
              </button>
              <button
                className='btn danger-btn  text-capitalize'
                data-dismiss='modal'
                onClick={() => {
                  setFaqPopup(false);
                  Navigate('/chatbot/customizewidget', { state: null });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomizeWidget;
