import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import Header from '../../components/Header';
import { BsArrowLeft } from 'react-icons/bs';
import CHIRPFLO_LOGO from '../../assets/icons/ChirpfloIcon-V2.0/bigChirpflo-icon.svg';
import { deleteWithToken, getLocalStorage, getWithToken, patchWithToken, postWithToken } from '../../API/Api';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51OhTorHUsPaFQUc9wuCjdoh6fFHjfkegusNrcuYp9SXptJI4TcsjQuijj0CZDzD9rC2ba7OJiojMMfY10O13RzGz00gBVCndqk');

const Subscription = () => {
  const [subscriptionTab, setSubscriptionTab] = useState('1');
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [apiTokenData, setApiTokenData] = useState('');
  const [upgradePopup, setUpgradePopup] = useState('');
  const [unsubsPopup, setUnsubsPopup] = useState('');
  const [subscriptionData, setSubscriptionData] = useState({
    subscriptionId: '',
    clientSecret: '',
  });

  const [productsData, setProductsData] = useState();

  useEffect(() => {
    let token = getLocalStorage('apiToken');
    if (token) {
      setApiTokenData(JSON.parse(token));
      getSubscriptionDetails(JSON.parse(token));
    }
  }, []);

  const getSubscriptionDetails = async (token) => {
    try {
      const response = await getWithToken('list_subscription', token);
      if (response?.data) {
        setSubscriptionDetails(response?.data);
      } else {
        console.log('No products found in the response.');
      }
    } catch (error) {
      console.error('Error while fetching subscription details:', error);
    }
  };
  const checkoutSubscriptionData = async (id, products) => {
    try {
      setProductsData(products);
      if (!id) {
        console.log('No ID provided for subscription checkout.');
        return;
      }

      if (!subscriptionDetails?.user?.is_subscription_status) {
        const response = await postWithToken('create_subscription', apiTokenData, { price_id: id });

        if (response?.status === 200) {
          setSubscriptionTab('3');
          setSubscriptionData({ subscriptionId: response?.subscriptionId, clientSecret: response?.clientSecret });
        } else if (response?.status === 400) {
          toast.error(response.message);
        } else {
          console.log('No products found for the provided subscription ID.');
        }
      } else {
        setUpgradePopup(true);
      }
    } catch (error) {
      console.error('Error while fetching subscription details:', error);
    }
  };

  const handleUpgradeData = async (productsData) => {
    try {
      const response = await patchWithToken('update_subscription', apiTokenData, { price_id: productsData?.price_id });

      if (response?.status === 200) {
        setUpgradePopup(false);
        window.location.reload();
      } else if (response?.status === 400) {
        toast.error(response.message);
      } else {
        console.log('No products found for the provided subscription ID.');
      }
    } catch (error) {
      console.error('Error while updating subscription:', error);
    }
  };

  const appearance = {
    theme: 'flat',

    variables: {
      colorPrimary: '#4B4D7E',
      colorBackground: '#26224A',
      colorText: '#ffffff',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      borderRadius: '8px',
      height: '50px',
    },
    rules: {
      '.Input, .Block': {
        border: '1.5px solid var(--colorPrimary)',
      },
    },
  };

  const cancelSubscriptionData = (apiTokenData) => {
    deleteWithToken('cancel_subscription', apiTokenData)
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
          toast.success(response?.message);
        } else if (response.code == 'token_not_valid') {
        }
      })
      .catch((error) => {
        toast.error('Something went wrong');
      });
  };
  return (
    <div className='intelichat-conatiner-right'>
      <div className='chatbot-container'>
        <div className='chatbot-fixed header-responsive'>
          <div className='d-block d-sm-flex flex-wrap align-items-center justify-content-between'>
            <h3 className='opensans-bold mb-0'>Your Subscription</h3>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='search-lead input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  <FiSearch />
                </span>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search here'
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                  // value={search}
                  // onChange={(e) => handleSearch(e)}
                />
              </div>
              <div className='d-flex justify-content-end responsive-header-width'>
                <Header />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='m-0 mt-5 px-md-5 px-sm-3 px-3 mb-5'>
        <div className='mb-5'>
          <h3 className='opensans-semibold text-white'>
            <span className='lead-summary-color opensans-semibold'>Chirpflo</span> Plans Made Just Right for Your Needs
          </h3>
          <div className='d-flex flex-wrap justify-content-end align-items-center'>
            {/* <p className='font14 color-theme-darkLightblue opensans-regular m-0'>Find your perfect plan now</p> */}
            {subscriptionTab !== '1' && (
              <div className='stepper-wrapper'>
                <div className={`stepper-item ${subscriptionTab === '2' ? 'active' : 'completed'}`}>
                  <div className='step-name mb-2'>Add-Ons</div>
                  <div className='step-counter'></div>
                </div>
                <div className={`stepper-item ${subscriptionTab === '3' ? 'active' : 'completed'}`}>
                  <div className='step-name mb-2'>Pay</div>
                  <div className='step-counter'></div>
                </div>
                <div className='stepper-item'>
                  <div className='step-name mb-2'>Confirmed</div>
                  <div className='step-counter'></div>
                </div>
              </div>
            )}
          </div>
        </div>
        {subscriptionTab == '1' && (
          <div className='row subscription-content g-5 mx-0'>
            {subscriptionDetails?.list_subscription?.map((products, index) => (
              <div className='col-lg-4 col-md-12 col-sm-12 col-12' key={index}>
                <div
                  className={`card Pro subscription-box ${
                    products?.price == 0 && !subscriptionDetails?.user?.subscription?.id
                      ? 'active-subscription-border'
                      : products?.id == subscriptionDetails?.user?.subscription?.id
                      ? 'active-subscription-border'
                      : 'inactive-subscription-border'
                  }`}
                >
                  {products?.is_best_seller && (
                    <>
                      <div className='subscription-best'>
                        <p className='text-white font14 opensans-semibold m-0'>Best Savings</p>
                      </div>
                      <div className='subscription-logo'>
                        <img src={CHIRPFLO_LOGO} alt='chirpflo-logo' />
                      </div>
                    </>
                  )}
                  <div className='card-body p-0'>
                    <h4 className='card-title lead-summary-color opensans-regular'>{products?.name}</h4>
                    <div className='subscription-cost mt-2'>
                      <h1 className='card-title text-white opensans-regular'>
                        ${products?.price}
                        <span className='font-italic font16 color-theme-darkLightblue opensans-regular'>/month</span>
                      </h1>
                      <p className='card-text font16 color-theme-darkLightblue mb-0 opensans-regular'>{products?.description}</p>
                    </div>
                    <div className='subscription-button my-4'>
                      <button
                        className='btn font-weight-bold font16 opensans-regular text-white'
                        disabled={products?.price == 0 || products?.id == subscriptionDetails?.user?.subscription?.id}
                        style={{
                          background: products?.id === subscriptionDetails?.user?.subscription?.id ? 'transparent' : 'linear-gradient(to right, #579df1, #69dfe1)',
                          borderColor: products?.id === subscriptionDetails?.user?.subscription?.id ? '#579df1' : 'linear-gradient(to right, #579df1, #69dfe1)',
                        }}
                        onClick={() => products?.id != subscriptionDetails?.user?.subscription?.id && checkoutSubscriptionData(products?.price_id, products)}
                      >
                        {/* {products?.price === 0 || !subscriptionDetails?.user?.is_subscription_status
                          ? 'Get Started'
                          : products?.id === subscriptionDetails?.user?.subscription?.id
                          ? 'Active '
                          : 'Upgrade'} */}

                        {products?.price === 0 || !subscriptionDetails?.user?.is_subscription_status
                          ? 'Get Started'
                          : products?.id === subscriptionDetails?.user?.subscription?.id
                          ? 'Active'
                          : subscriptionDetails?.user?.subscription?.name === 'Pro'
                          ? 'Downgrade'
                          : 'Upgrade'}
                      </button>
                    </div>
                    <div className='custom-line mb-4'>
                      <p className='card-text font16 text-white mb-0 opensans-regular'>Get the following benefits</p>
                    </div>
                    <div className='subscription-description'>
                      <ul>
                        {products?.features.map((item, index) => (
                          <li key={index}>{item?.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {products?.id === subscriptionDetails?.user?.subscription?.id && (
                    <div className='subscription-button my-2'>
                      <button
                        className='btn font-weight-bold font16 opensans-regular text-white'
                        disabled={products?.price == 0}
                        style={
                          {
                            // background: products?.id === subscriptionDetails?.user?.subscription?.id ? '#600101ab' : 'transparent',
                            // borderColor: products?.id === subscriptionDetails?.user?.subscription?.id ? '#600101ab' : '#579df1',
                          }
                        }
                        onClick={() => setUnsubsPopup(true)}
                      >
                        Unsubscription
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {subscriptionTab == '2' && (
          <div className='row m-0 mt-5 mb-5 justify-content-between'>
            <div className='col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12'>
              <div className='chatbox-field bg-themeLightBlue'>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <BsArrowLeft className='font25 color-theme-darkLightblue cursor-pointer' onClick={() => setSubscriptionTab('1')} />
                    <h4 className='font18 lead-summary-color opensans-bold m-0 ms-2 mt-2 mt-md-0'>Basic Package</h4>
                  </div>
                  <div className='mt-2 mt-md-0'>
                    <span className='font16 color-theme-darkLightblue opensans-regular m-0'>$49.95</span>
                  </div>
                </div>
                <div className='custom-hr my-4'></div>
                <div className='addons-container'>
                  <p className='font18 color-theme-darkLightblue opensans-semibold mb-4'>Add-Ons</p>

                  <div className='row addons-content align-items-center mb-4'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between '>
                        <div className='addons-info'>
                          <p className='font16 text-white opensans-regular m-0'>Additional Messages</p>
                          <span className='font16 color-theme-darkLightblue opensans-regular m-0'>$50 per 5,000 messages (excluding Free Tier)</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='addons-option'>
                          <div className='chatbox-input-text'>
                            <select className='form-select box-shadow-hover color-theme-darkLightblue font14 lead-select' style={{ width: '200px' }}>
                              <option value=''>Select Messages</option>
                              <option value='1'>Messages</option>
                            </select>
                          </div>
                        </div>
                        <span className='addons-price font16 color-theme-darkLightblue opensans-regular m-0'>$49.95</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mt-md-0'>
              <div className='chatbox-field bg-themeLightBlue'>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                  <h4 className='font18 text-white opensans-semibold m-0 mt-2 mt-md-0'>Summary</h4>
                </div>
                <div className='custom-hr my-4'></div>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                  <div className=''>
                    <h4 className='font16 color-theme-darkLightblue opensans-regular m-0 mt-2 mt-md-0'>Subtotal</h4>
                  </div>
                  <div className='mt-2 mt-md-0'>
                    <span className='font16 color-theme-darkLightblue opensans-regular m-0'>$548.95</span>
                  </div>
                </div>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mt-2'>
                  <div className=''>
                    <h4 className='font16 color-theme-darkLightblue opensans-regular m-0 mt-2 mt-md-0'>VAT/Sales Tax 0%</h4>
                  </div>
                  <div className='mt-2 mt-md-0'>
                    <span className='font16 color-theme-darkLightblue opensans-regular m-0'>$0.00</span>
                  </div>
                </div>
                <div className='custom-hr my-4'></div>
                <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mt-2'>
                  <div className=''>
                    <h4 className='font18 text-white opensans-semibold m-0 mt-2 mt-md-0'>Total Amount</h4>
                  </div>
                  <div className='mt-2 mt-md-0'>
                    <span className='font18 text-white opensans-semibold m-0'>$548.95</span>
                  </div>
                </div>
                <div className='mt-4'>
                  <button className='btn btn-submit-login w-100' style={{ maxWidth: '100%' }} onClick={() => setSubscriptionTab('3')}>
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {subscriptionTab == '3' && (
          <>
            {subscriptionData?.clientSecret && (
              <Elements options={{ clientSecret: subscriptionData?.clientSecret, appearance }} stripe={stripePromise}>
                <CheckoutForm setSubscriptionTab={setSubscriptionTab} productsData={productsData} />
              </Elements>
            )}
          </>
        )}
      </div>
      {upgradePopup && (
        <div className='custom-popup'>
          <div className='popup-content' style={{ maxWidth: '420px' }}>
            <div className={'popup-head text-center'}>
              <h4 className='font18 color-theme-darkLightblue opensans-regular'>
                {`Do you want ${subscriptionDetails?.user?.subscription?.name === 'Pro' ? 'Downgrade' : 'UPGRADE'} your plan?`}
              </h4>
              <p className='font16 color-theme-darkLightblue opensans-regular mb-0'>Your current plan will be unsubscribed now! </p>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn success-btn text-capitalize me-2'
                style={{ background: 'linear-gradient(to right, #579df1, #69dfe1)', border: 'none' }}
                onClick={() => handleUpgradeData(productsData)}
              >
                {subscriptionDetails?.user?.subscription?.name === 'Pro' ? 'Downgrade' : 'Upgrade'}
              </button>
              <button className='btn danger-btn  text-capitalize' data-dismiss='modal' onClick={() => setUpgradePopup(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {unsubsPopup && (
        <div className='custom-popup'>
          <div className='popup-content' style={{ maxWidth: '420px' }}>
            <div className={'popup-head text-center'}>
              {/* <h4 className='font18 color-theme-darkLightblue opensans-regular'>Do you want UPGRADE your plan? </h4> */}
              <p className='font16 color-theme-darkLightblue opensans-regular mb-0'>Your current plan will be unsubscribed now? </p>
            </div>
            <hr />
            <div className='popup-footer text-center'>
              <button
                className='btn success-btn text-capitalize me-2'
                style={{ background: 'linear-gradient(to right, #579df1, #69dfe1)', border: 'none' }}
                onClick={() => cancelSubscriptionData(apiTokenData)}
              >
                Yes
              </button>
              <button className='btn danger-btn  text-capitalize' data-dismiss='modal' onClick={() => setUnsubsPopup(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
